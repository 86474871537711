import React, { useState } from "react";
import Login from "./Login";
import OTP from "./OTP";
import AuthContext from "../../../../context/Auth";
import toast, { Toaster } from "react-hot-toast";


function Signup({fun} ) {

  let { loginUser, logoutUser, isAuthenticated, signupUser, otpstage, setotpstage } =
  React.useContext(AuthContext);
  const [otp, setotp] = React.useState(false);

  const regex = {
    phonenumber: /^[0-9]{10}$/,
    email: /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/,
    username: /^[a-zA-Z0-9_-]{3,16}$/,
    password: /^[a-zA-Z0-9!@#$%^&*]{6,16}$/,
    otp: /^[0-9]{6}$/,
  };

  

  const [showComponent1, setShowComponent1] = useState(true);
  const [showComponent2, setShowComponent2] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isValid, setIsValid] = useState(false);

      const handleProceedClick = (phoneNumber) => {
        setShowComponent1(false);   
        setShowComponent2(true);
        setPhoneNumber(phoneNumber);
      };

      const handleEditNumberClick = () => {
        setShowComponent2(false);
        setShowComponent1(true);
      };
      
      const handleloginUser = (e) => {
        e.preventDefault();
        const phonenumber = e.target.phonenumber.value;
        const otp = e.target.otp.value;
        if (!regex.phonenumber.test(phonenumber)) {
          toast.error("Please enter a valid phone number");
          return;
        }
        if (!regex.otp.test(otp)) {
          toast.error("OTP Length must be 6");
          return;
        }
        // if(!regex.password.test(otp)){
        //   toast.error("Please enter a valid OTP")
        //   return
        // }
        // if (btnClick) {
        //   // setSkip(true);
        //   loginUser(phonenumber, otp);
        // } else {
        //   loginUser(phonenumber, otp);
        // }
        loginUser(phonenumber, otp);
      };

      const otprequest = async (e) => {
        e.preventDefault();
        if (!regex.phonenumber.test(e.target.phonenumber.value)) {
          toast.error("Please enter a valid phone number");
          return;
        }
        const response = await fetch("https://trakky.in:8000/salons/otp/", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone_number: e.target.phonenumber.value,
          }),
        });
    
        if (response.status === 200) {
          setotp(true);
          toast.success("OTP sent");
        } else {
          toast.error("error while sending otp please try again");
        }
      };
      React.useEffect(() => {
        if (isAuthenticated) {
          fun();
          setotp(false);
      
        } else {
          setotp(false);
        }
      }, [isAuthenticated]);

      React.useEffect(() => {
        logoutUser();
      }, [logoutUser]);

  return (
    <>
      {showComponent1 && (
        <Login
          setPhone={setPhoneNumber}
          otpRequest={otprequest}
          setotp={setotp}
          onProceedClick={(phoneNumber) => handleProceedClick(phoneNumber)}
          fun={fun}
          signupUser={signupUser}

          
        />
      )}
      {showComponent2 && (
        <OTP
          otp={otp}
          loginUser={loginUser}
          handleloginUser={handleloginUser}
          phoneNumber={phoneNumber}
          onEditNumberClick={handleEditNumberClick}
          isAuthenticated={isAuthenticated}
          fun={fun}
          signupUser={signupUser}
          otpRequest={otprequest}
          setotp={setotp}
        />
      )}
    </>
  );
}

export default Signup;
