import React from "react";
import salonLogo from "../salon-logo.png";
function HistoryBooking() {
  return (
    <div className="OuterBoxHist">
      <div className="HistCardPP">
        <img src={salonLogo} alt="" />
        <div className="salondetailHist">
          <span className="SalonNameHist"> <h1>Black Panther Salon</h1></span>
          <span className="SalonPackageHist">Bridal Package</span>
          <span style={{display:'flex', fontSize:'12px', gap:'15px', color:'black',justifyContent:'space-around'}} className='Histamtpaid' >
            <span className="DateHist">26 Jun, 2024 | 5:00 PM</span>
            <span className="AddressHist">Odhav, Ahmedabad, Gujarat</span>
          </span>
          <span style={{display:'flex', fontSize:'12px', gap:'15px', color:'black',justifyContent:'space-between'}} className='Histamtpaid'>
            <span className="DateHist">Amount Pais</span>
            <span className="AddressHist">₹5000</span>
          </span>
        </div>
       
      </div>
      <div className="HLHist"></div>
      <span style={{width:'100%',display:'flex',justifyContent:'center'}} className="appreciateHist">Hope you liked the service</span>
    </div>
  );
}

export default HistoryBooking;
