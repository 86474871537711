import React, { useState,useEffect } from 'react';
import './RateUs.css';
import StarsIcon from '@mui/icons-material/Stars';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon1 from '@mui/icons-material/Star';
const RateUs = () => {
  const [rating, setRating] = useState(0); // State to store the rating
  const [hoveredStar, setHoveredStar] = useState(0); // State to store the hovered star

  const handleStarHover = (starIndex) => {
    setHoveredStar(starIndex); // Update the hovered star state
  };

  const handleStarClick = (starIndex) => {
    setRating(starIndex); // Save the rating when a star is clicked
  };
  return (
    <div className='PPRateus'>
      <div className='PPRateUsOuterBox'>
        <div className='PPRateUS'>
          <StarsIcon className='StarRatePP' />
          <h1>Feel Free To Rate</h1>
          <h1>Tell Us About Your Experience</h1>
          <div className="starsPP">
            {[...Array(5)].map((_, index) => (
              <StarIcon1
                key={index}
                className={index < rating || index < hoveredStar ? 'rateStarsPP filled' : 'rateStarsPP'}
                onMouseEnter={() => handleStarHover(index + 1)}
                onMouseLeave={() => setHoveredStar(0)}
                onClick={() => handleStarClick(index + 1)}
              />
            ))}
          </div>
          <div className="btnrateUs">
            Submit
          </div>
        </div>
        <div className='TrakkyLovePP'>Trakky Loves You ❤️</div>
      </div>
    </div>
  );
};

export default RateUs;
