import React from 'react'
import styles from './Cards.module.css'
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import MailIcon from '@mui/icons-material/Mail';
import { Link } from 'react-router-dom';
function Cards() {
  return (
    <div className={styles.Contactus_main}>
            <div className={styles.Contactus_card}>
                <p className={styles.Contactus_heading}>Report a Concern</p>
                <p className={styles.Contactus_number}><WhatsAppIcon/> <span style={{paddingLeft:'5px'}}> <a href="tel:6355167304 ">+91 6355167304 </a></span> </p>
                <p className={styles.Contactus_number}><WhatsAppIcon/> <span style={{paddingLeft:'5px'}}> <a href="tel:9328382710">+91 9328382710</a> </span> </p>
                <p className={styles.Contactus_number}><MailIcon/> <span style={{paddingLeft:'5px'}}>customercare@trakky.in</span> </p>
            </div>
            <div className={styles.Contactus_card} >
                <p className={styles.Contactus_heading} style={{paddingBottom:"60px"}}>For Partner Registration</p>
              <div style={{display:"flex", justifyContent:"center"}}>
             <Link to='/vendor-page'><button className={styles.Contactus_button}>Visit Now</button></Link>   
                </div>  
            </div>
    </div>
  )
}

export default Cards