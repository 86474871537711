import React from 'react'
import salonLogo from "../salon-logo.png";
function CurrentBooking() {
  return (
    <div className="outerBoxCB">
        <div className="innerBoxCB">
    <div className='containerBooking'>
       
            <img src={salonLogo} alt="" className='bookingIMGCard'/>
            <h1>Black Panther Salon</h1>
            <span>Vastrapur, Ahmedabad</span>
            <span>September 24, 7:32 PM</span>
            <span style={{color:'black',fontSize:'18px'}}>₹520</span>
            <div className="dottedlineBooking"></div>
            <div className='orderBookingPP'>
                <img src={salonLogo} alt="" className='orderImagePP'/>
                <div className="categoryservicePP">
                  <span style={{display:'flex'}}>  <span style={{paddingRight:'130px'}}>Hair</span><span >₹520</span></span>
                    <span style={{color:'black'}}>Regular Hair Wash</span>
                </div>
            </div>
            <div className='orderBookingPP'>
                <img src={salonLogo} alt="" className='orderImagePP'/>
                <div className="categoryservicePP">
                  <span style={{display:'flex'}}>  <span style={{paddingRight:'130px'}}>Hair</span><span >₹520</span></span>
                    <span style={{color:'black'}}>Regular Hair Wash</span>
                </div>
            </div>
            <div className='orderBookingPP'>
                <img src={salonLogo} alt="" className='orderImagePP'/>
                <div className="categoryservicePP">
                  <span style={{display:'flex'}}>  <span style={{paddingRight:'130px'}}>Hair</span><span >₹520</span></span>
                    <span style={{color:'black'}}>Regular Hair Wash</span>
                </div>
            </div>
            <div className="orderIDPP">
                <span>Order ID</span>
                <span>T4W21L</span>
            </div>
            <div className="orderIDPP">
                <span>Coins Used</span>
                <span>₹0</span>
            </div>
            <div className="orderIDPP">
                <span>Sub Total</span>
                <span>₹520</span>
            </div>
            <div className="orderIDPP">
                <span>Discount (2%)</span>
                <span>₹10.4</span>
            </div>
            <div className="dottedlineBooking"></div>
            <div className="btnrescan">
                <div className='ReschedulePP'>Reschedule</div>
                <div className='CancelPP'>Cancel</div>
            </div>
    </div>
    </div>
    </div>
  )
}

export default CurrentBooking