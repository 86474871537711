import React from 'react';
import './Feedback.css'; // Import the CSS file for styling

const Feedback = () => {

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevent default form submission behavior
    const formData = new FormData(event.target);
    const name = formData.get('username');
    const phone = formData.get('phonenumber');
    const email = formData.get('email');
    const message = formData.get('message');
    
    // Handle the submission logic, e.g., send a request to the server
    // Add further actions as needed
  };

  return (
    <div >
      

      <form className='feedback-form' onSubmit={handleSubmit}>
      <h1 className="edit-P-C-heading">Feedback</h1>
        <div className="input-row">
          <div className="PP-form-item">
            <input type="text" name="username" id="username" required />
            <label htmlFor="username">Name</label>
          </div>
          <div className="PP-form-item">
            <input type="tel" name="phone-number" required />
            <label htmlFor="phone-number">Phone No.</label>
          </div>
        </div>

        <div className="PP-form-item">
          <input type="email" name="email" required />
          <label htmlFor="email">Email</label>
        </div>

        <div className="feedback-textarea">
          <textarea name="message" className='txtarea' required ></textarea>
          <label htmlFor="txtarea">Share your feedback....</label>
        </div>

        <button className="btn" type="submit">SUBMIT</button>
      </form>
    </div>
  );
};

export default Feedback;
