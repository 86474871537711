import React from "react";
import HowTrakkyWorks from "./HowTrakkyWorks";
import Marque from "./Marque";
import Navbar from "./Navbar";
import PartnerUs from "./PartnerUs";
import Review from "./Review";
import Faqs from "./Faqs";
import Footer from '../Common/Footer/Footer'
import './Vendor.css'
function Vendor() {
  return (
    <div>
      <Navbar />
      <HowTrakkyWorks />
      <PartnerUs />
      <Marque />
      <Review />
      <Faqs />
      <Footer />
    </div>
  );
}

export default Vendor;
