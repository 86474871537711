import React from 'react'
import styles from './Title.module.css'
function Title() {
  return (
    <div className={styles.contactus_title_main}>
        <p className={styles.contactus_title_title}>Contact Us</p>
    </div>
  )
}

export default Title