import React from "react";
import "./salonprofilemodal.css";
import { IoMdClose } from "react-icons/io";


const OfferSalonModal = ({ data, handleClose, salon }) => {
  const handleBookNowBtn = (name , price) => {
    if (!salon?.name) {
      return;
    }

    let message = `Can you provide more details about the special offer named '${name}' at ${salon?.name} in ${salon?.area}, ${salon?.city}? It comes with a discounted price of ${price} rupees.`

    let link = `https://api.whatsapp.com/send?phone=916355167304&text=${encodeURIComponent(message)}`;

    window.open(link, "_blank");
  };

  return (
    <>
      <div className="Membership-Modal-main-container">
        <div className="membership-close-btn">
          <div className="button" onClick={handleClose}>
            <IoMdClose sx={{
              height: "30px",
              width: "30px",
            }}/>
          </div>
        </div>
        <div className="MSM-details">
          <div className="MSM-Title-header text-center !font-semibold">
            Offers Details
          </div>
          <div className="MSM-membership-meta-info">
            <div className=" h-28 w-auto rounded-md">
              <img src={data?.image} alt="" className=" h-28 w-auto rounded-md"/>
            </div>
            <div className="MSM-m-m-info-offer">
              <h2 className="text-[20px] font-semibold ">{data?.name}</h2>
            </div>
            <div className="flex justify-between OSM-price-cart">
              <div>
                <del>₹{data?.actual_price}</del>
                <div className="text-[18px] font-medium pl-1">
                  ₹{data?.discount_price} only
                </div>
              </div>
              <button
                onClick={() => {
                  handleBookNowBtn(data?.name, data?.discount_price);
                }}
              >
                {/* Add to cart */}
                Book Now
              </button>
            </div>
          </div>
          <div className="MSM-membership-desc !pt-2">
            <h2 className=" font-semibold text-center text-[18px] mb-2 mt-0">
              Terms & conditions
            </h2>

            <div
              dangerouslySetInnerHTML={{
                __html: data?.terms_and_conditions,
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferSalonModal;
